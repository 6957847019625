export default offence => {
  const protocolsObjects = offence.filter(item => item.owner_type_id === 216);
  const protocolsPlace = offence.filter(item => item.owner_type_id === 217);
  return [
    {
      title: `Протоколы на владельца объекта (${protocolsObjects.length} шт.)`,
      props: {
        date: 'offence_protocol_object_owner_date',
        number: 'offence_protocol_object_owner_number',
        status: 'status_name',
        penalty: 'amount_of_penalty_object_owner',
        penalty_payment: 'amount_of_penalty_object_owner_paid'
      },
      offence: protocolsObjects
    },
    {
      title: `Протоколы на владельца участка (${protocolsPlace.length} шт.)`,
      props: {
        date: 'offence_protocol_place_owner_date',
        number: 'offence_protocol_place_owner_number',
        status: 'status_name',
        penalty: 'amount_of_penalty_place_owner',
        penalty_payment: 'amount_of_penalty_place_owner_paid'
      },
      offence: protocolsPlace
    }
  ];
};
